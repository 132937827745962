<template>
	<div class="page webschool_page">
		<div class="block30"></div>
		<div class="w1240">
			<div class="webschool_typebox" v-if="isLogin&&myclassLits.length>0">
				<div class="line"></div>
				<div class="text fontsize32">我的课程</div>
			</div>
			<div class="myclassbox">
				<div class="itemobj" v-for="(item,index) in myclassLits" :key="index">
					<div class="icon"><img class="img100" src="../assets/static/Frame65640.png"/></div>
					<div class="imgbox"><img class="img100" :src="item.imagesUrl"/></div>
					<div class="titlename clamp fontsize20">{{item.title}}</div>
					<div class="bottomcon fontsize14" @click="handleclass(item.id)">
						<div class="text1" v-if="item.trainUserSchedule.state==0">开始学习</div>
						<div class="text1" v-if="item.trainUserSchedule.state==1">继续学习</div>
						<div class="text1" v-if="item.trainUserSchedule.state==2">继续学习</div>
						<div class="text2" v-if="item.trainUserSchedule.state==2">学习完成</div>
						<div class="text3" v-if="item.trainUserSchedule.state==1">学习中</div>
						<div class="text4" v-if="item.trainUserSchedule.state==0">未开始</div>
					</div>
				</div>
				<div class="nolist fontsize16" v-if="isLogin&&myclassLits.length<=0">您暂无拥有课程，请查询下方课程加入!</div>
				<div class="nolist fontsize16" v-if="!isLogin">请先登录!</div>
			</div>
			<div class="block58"></div>
			<div class="webschool_typebox">
				<div class="line"></div>
				<div class="text fontsize32">全部课程</div>
			</div>
			<div class="label_box1">
				<div class="labeldiv fontsize20">类型：</div>
				<div class="datalist">
					<div class="itemobj fontsize16" v-for="(item,index) in typeList" :key="index">
						<span @click="handleType(index)" :class="{'active':index==typeindex}">{{item.title}}</span>
					</div>
				</div>
			</div>
			<div class="label_box1">
				<div class="labeldiv fontsize20">分类：</div>
				<div class="datalist">
					<div class="itemobj fontsize16" v-for="(item,index) in typeList1" :key="index">
						<span @click="handleType1(index)" :class="{'active':index==typeindex1}">{{item.title}}</span>
					</div>
				</div>
			</div>
			<div class="hxline"></div>
			<div class="datalist_box">
				<div class="itemobj" v-for="(item,index) in classList" :key="index">
					<div class="objbox">
						<div class="imgbox"><img class="img100" :src="item.imagesUrl"/></div>
						<div class="title fontsize20 clamp">{{item.title}}</div>
						<div class="desc fontsize14">
							<div class="free" v-if="item.allOpen==0">免费</div>
							<div class="nofree" v-if="item.allOpen>0">¥{{item.schedulePrice}}</div>
							<div class="num">已有{{(item.memNum + item.studyNum)}}人加入学习</div>
						</div>
						<div class="addbtn" @click="handleclass(item.id)">加入学习</div>
					</div>
				</div>
			</div>
			<div></div>
			<div style="margin-top: 20px;text-align: center;width: 100%;" v-if="!complete&&classList.length>0"><el-button :loading="loadingshow" @click="getPageSchedule">点击加载更多</el-button></div>
			<div style="margin-top: 20px;text-align: center;width: 100%;" v-if="complete"><el-button>已经没有了~</el-button></div>
		</div>
		<div class="block30"></div>
	</div>
</template>

<script>
import {mapState} from 'vuex'
export default {
	data() {
		return {
			myclassLits:[],
			typeList:[
				{
					id:-1,
					title:"全部",
				},
				{
					id:0,
					title:"免费",
				},
				{
					id:1,
					title:"精品",
				}
			],//类型
			typeindex:0,//
			typeList1:[{
				id:-1,
				title:"全部",
			}],//分类
			typeindex1:0,
			currentPage:1,
			pageSize:12,
			complete:false,//加载全部
			loadingshow:false,
			classList:[],
		};
	},
	mounted: function() {
		//课程目录
		this.getPageSchedule()
		//课程目录类型
		this.getPagetype()
		//获取我目录
		this.userGetSchedule()
	},
	computed: {
		...mapState(['userInfo', 'isLogin','sysRole']),
	},
	methods: {
		//获取我目录
		userGetSchedule(){
			var _this = this
			//需要登录
			if(this.isLogin){
				var params = {}
				params["currentPage"] = 1
				params["pageSize"] = 8888
				params["userUuid"] = this.userInfo.uuid
				this.$http.post('TrainModule/train-user-schedule2/userGetSchedule', params).then(function(res) {
					//数据处理
					if (res.code == 200) {
						_this.myclassLits = res.data.records
					} else {
						_this.$confirm(res.message, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {}).catch(() => {});
					}
				})
			}
		},
		//下标
		handleType(type){
			this.typeindex = type
			this.classList = []
			this.currentPage = 1
			this.complete = false
			//课程目录
			this.getPageSchedule()
		},
		handleType1(type){
			this.typeindex1 = type
			this.classList = []
			this.currentPage = 1
			this.complete = false
			//课程目录
			this.getPageSchedule()
		},
		//课程目录类型
		getPagetype() {
			var _this = this
			var params = {}
			params["currentPage"] = 1
			params["pageSize"] = 888
			this.$http.post('TrainModule/train-course-type/getPage', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					var records = res.data.records
					_this.typeList1 = _this.typeList1.concat(records)
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		//课程目录
		getPageSchedule() {
			var _this = this
			if(_this.complete){
				return false;
			}
			this.loadingshow = true
			var params = {}
			params["currentPage"] = this.currentPage
			params["pageSize"] = this.pageSize
			params["allOpen"] = null
			params["typeCode"] = null
			if(this.typeindex>0){
				params["allOpen"] = this.typeList[this.typeindex].id
			}
			if(this.typeindex1>0){
				params["typeCode"] = this.typeList1[this.typeindex1].typeCode
			}
			this.$http.post('TrainModule/train-user-schedule2/getPageSchedule', params).then(function(res) {
				_this.loadingshow = false
				//数据处理
				if (res.code == 200) {
					var records = res.data.records
					if(records.length>0){
						_this.classList = _this.classList.concat(records)
						_this.currentPage +=1
					}else{
						_this.complete = true
					}
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		//课程
		handleclass(uid){
			//需要登录
			if(!this.isLogin){
				this.$message.error("请先登录账号！")
				return;
			}
			this.$router.push({
				path: "/class",
				query:{
					uid:uid
				}
			});
		},
		
		
	}
};
</script>
<style lang="scss" scoped></style>
